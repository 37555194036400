import React from "react";
import AppButton from "../../../common/AppButton/AppButton";

import { getAnalytics, logEvent } from "firebase/analytics";

import style from "./Description.module.scss";

interface IProps {
    text: string;
    url: string;
    id: string;
}
const analytics = getAnalytics();

const linkClick = (url: string, id: string) => {
    logEvent(analytics, "link_click", {
        item_id: id,
    });
    window.open(url, '_blank');
};

const Description = ({ text, url, id }: IProps) => {
    return (
        <div className={style.description}>
            <h2>Description</h2>
            <p>{text}</p>
            {
                <AppButton type="contained"
                    disabled={false} additionalClassNames={style.buyButton}
                    children={<>
                        <a href={url} target="_blank" rel="noopener noreferrer">Learn more, try, buy</a>
                    </>}
                    onClick={() => linkClick(url, id)} />
            }
        </div>
    );
};

export default Description;